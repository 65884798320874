<script>
import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { tableData } from "./data-advancedtable";
import Swal from "sweetalert2";
import {
  required
} from "vuelidate/lib/validators";
import _ from 'lodash'
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Toastify from 'toastify-js';
import DatePicker from "vue2-datepicker";
import moment from 'moment';
import { propertyMethods } from "../../../state/helpers/property";
import { authUserMethods } from "../../../state/helpers/authuser"
import { utilityItemMethods } from "../../../state/helpers/utilityitem";
import { amenityItemMethods } from "../../../state/helpers/amenityitem";
import { parkingTypeItemMethods } from "../../../state/helpers/parkingtypeitem";
import { propertyTypeItemMethods } from "../../../state/helpers/propertytypeitem";
import { currencyMixin } from '../../mixins/currencyMixin';
import { formatCurrencyMixin } from '../../mixins/formatCurrencyMixin';
import { formatAmount } from '../../mixins/formatAmountMixin';
import { formatDateMixin } from "../../mixins/formatDateMixin";
import validateObjectMixin  from "../../mixins/validateObjectMixin";
import draggable from 'vuedraggable';

/**
 * Blank page component
 */

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file.file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export default {
  page: {
    title: "Property List",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { ckeditor: CKEditor.component, Layout, DatePicker, draggable },
  mixins: [currencyMixin, formatCurrencyMixin, formatAmount, formatDateMixin, validateObjectMixin],
  display: "Transition",
  data() {
    return {
      tableData: tableData,
      title: "Property List",
      active_column: '',
      transProps: {
          // Transition name
          name: 'flip-list'
        },
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Properties List",
          href: "/",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      is_sort_asc: true,
      is_sort_alha_asc: false,
      is_alpha_enable: false,
      is_sort_ascdesc_enable: false,
      perPage: 15,
      page: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: false,
      fields: [
        { key: "property_name"},
        { key: "owner_full_name", label: "Client"},
        { key: "monthly_rent_wanted", label: "Rent"},
        { key: "min_lease_term", label: 'Min Lease' },
        { key: "max_lease_term", label: 'Max Lease' },
        { key: "bedroom",  label:'Bed' },
        { key: "size" },
        { key: "available_property_date", label: 'Date Available'},
        { key: "lease_start_date", label: 'Lease Start'},
        { key: "lease_end_date", label: 'Lease End' },
        { key: "property_type" },
        { key: "status",},
      ],
      tab_items: [
        { id: 1, title: "Infomation" },
        { id: 2, title: "Description" },
        { id: 3, title: "Leasing Details" },
        { id: 4, title: "Parking" },
        { id: 5, title: "Gallery" },
        { id: 6, title: "Availablity" },
      ],
      is_tab_active: 1,
      
      is_update: false,
      is_add_new: false,
      form: {
        fname: "",
        lname: "",
        city: "",
        state: "",
        zipcode: "",
      },
      property: {
        property_name: "",
        description: "",
        monthly_rent_wanted: "",
        min_lease_term: null,
        max_lease_term: null,
        min_security_deposit: null,
        bedroom: "",
        bath_full: "",
        bath_half: "",
        size: "",
        street_address: "",
        city: "",
        state: "",
        zip_code: "",
        longitude: "",
        latitude: "",
        furnished: "yes",
        featured: 'no',
        parking_spaces: "no",
        client_id: null,
        is_lease_the_start_date_and_end_date: "no",
        parking_number: "",
        parking_fees: null,
        amenity: [],
        parking_type: [],
        utility_include: [],
        property_type: "",
        property_gallery: [],
        available_property_date: null,
        lease_start_date: null,
        lease_end_date: null,
      },
      amount_wanted: null,
      property_types: [],
      parking_types: [],
      utility_includes: [],
      amenities: [],
      status_options: [
      {name: "available"},
      {name: "rented"},
      {name: "maintainance"},
      {name: "cancelled"},
      ],
      bedroom_options: [
        { value: 0, name: "Studio" },
        { value: 1, name: "1" },
        { value: 2, name: "2" },
        { value: 3, name: "3" },
        { value: 4, name: "4" },
        { value: 5, name: "5" },
        { value: 6, name: "6" },
        { value: 7, name: "7" },
        { value: 8, name: "8" },
        { value: 9, name: "9+" },
      ],
      bath_full_option: [
        { value: 1, name: 1 },
        { value: 2, name: 2 },
        { value: 3, name: 3 },
        { value: 4, name: 4 },
        { value: 5, name: 5 },
        { value: 6, name: 6 },
        { value: 7, name: 7 },
        { value: 8, name: 8 },
        { value: 9, name: "9+" },
      ],
      bath_half_option: [
        { value: 0, name: 0 },
        { value: 1, name: 1 },
        { value: 2, name: 2 },
        { value: 3, name: 3 },
        { value: 4, name: 4 },
        { value: 5, name: 5 },
        { value: 6, name: 6 },
        { value: 7, name: 7 },
        { value: 8, name: 8 },
        { value: 9, name: "9+" },
      ],
      options: [],
      client: null,
      property_name_option: true,
      submitted: false,
      property_status: '',
      editor: ClassicEditor,
      editorConfig: {
        toolbarLocation: "bottom",
      },
      isBusy: false,
      fileRecords: [],
      uploadUrl: 'https://www.mocky.io/v2/5d4fb20b3000005c111099e3',
      uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
      fileRecordsForUpload: [], // maintain an upload queue
      properties: [],
      galleries: [],
      search_data: "",
      amount_value: "",
      search_options: [
        { id: 1, type: "Search property for property owner" },
        { id: 2, type: "Search property for property owner and status" },
        { id: 3, type: "Search property for property type" },
        { id: 4, type: "Search property status" },
        { id: 5, type: "Search property availability" },
        { id: 6, type: "Search property for lease start and end date" },
        { id: 7, type: "Search property for property name" },
      ],
      active_search_option: 1,
      property_type: null,
      status: null,
      lease_start_date: null,
      lease_end_date: null,
    }
  },
  validations: {
    property: {
      property_name: { required },
      property_type: { required },
      monthly_rent_wanted: { required },
      min_lease_term: { required },
      max_lease_term: { required },
      min_security_deposit: { required },
      bedroom: { required },
      bath_half: { required },
      bath_full: { required },
      size: { required },
      street_address: { required },
      city: { required },
      state: { required },
      zip_code: { required },
      longitude: { required },
      latitude: { required },
      furnished: { required },
      parking_spaces: { required },
      featured: { required },
      is_lease_the_start_date_and_end_date: { required },
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
    formattedMonthlyAmountValue: {
      get() {
        const amount = parseFloat(this.property.monthly_rent_wanted);
        if (!isNaN(amount)) {
          return "$" + amount.toFixed(2);
        }
        return this.property.monthly_rent_wanted; // Return original input if not a valid number
      },
      set(value) {
        // Remove non-numeric characters except dot
        this.property.monthly_rent_wanted = value.replace(/[^0-9.]/g, "");
      },
    },
       formattedSecurityDeposite: {
      get() {
        const amount = parseFloat(this.property.min_security_deposit);
        if (!isNaN(amount)) {
          return "$" + amount.toFixed(2);
        }
        return this.property.min_security_deposit; // Return original input if not a valid number
      },
      set(value) {
        // Remove non-numeric characters except dot
        this.property.min_security_deposit = value.replace(/[^0-9.]/g, "");
      },
    },

    formattedParkingFees: {
      get() {
        const amount = parseFloat(this.property.parking_fees);
        if (!isNaN(amount)) {
          return "$" + amount.toFixed(2);
        }
        return this.property.parking_fees; // Return original input if not a valid number
      },
      set(value) {
        // Remove non-numeric characters except dot
        this.property.parking_fees = value.replace(/[^0-9.]/g, "");
      },
    },
  },
  watch: {
    search_data(data) {
      // eslint-disable-next-line no-console
      this.searchAllProperties(data)
    },
  },
  mounted() {
    this.getAllProperty()
    this.getAllAmenity()
    this.getAllUtilityInclude()
    this.getAllParkingType()
    this.getAllPropertyType()
  },
  methods: {
    ...propertyMethods,
    ...authUserMethods,
    ...amenityItemMethods,
    ...parkingTypeItemMethods,
    ...parkingTypeItemMethods,
    ...propertyTypeItemMethods,
    ...utilityItemMethods,
    async getAllProperty() {
      await this.allProperty({ paginate: true, page: this.page,  order_field: 'property_name', ranking: 'asc'.toUpperCase() }).then((response) => {
        this.properties = response.data
        this.totalRows = response.data.total
        // eslint-disable-next-line no-console
      })
    },
    formatInputValue() {
      // Remove non-numeric characters except dot
      // this.property.monthly_rent_wanted = this.inputValue.replace(/[^0-9.]/g, "");
    },
    getAllUtilityInclude() {
      return this.allUtilityItem({ paginate: false }).then((response) => {
        // eslint-disable-next-line no-console
        
        this.utility_includes = response.data.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
        
      })
    },
    getAllAmenity() {
      return this.allAmenityItem({ paginate: false }).then((response) => {
        // eslint-disable-next-line no-console
        
        this.amenities = response.data.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
        
      })
    },
    getAllParkingType() {
      return this.allParkingTypeItem({ paginate: false }).then((response) => {
        // eslint-disable-next-line no-console
        
        this.parking_types = response.data.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
        
      })
    },
    getAllPropertyType() {
      return this.allPropertyTypeItem({ paginate: false }).then((response) => {
        // eslint-disable-next-line no-console   
        this.property_types = response.data.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
      })
    },
    searchAllProperties(search) {
      return this.allProperty({ paginate: true, keyword: search }).then((response) => {
        // eslint-disable-next-line no-console
        this.properties = response.data
        this.totalRows = response.data.total
      })
    },
    onPageChange(page) {
      let query;

      // if(this.is_alpha_enable) {
      //     if(this.is_sort_alha_asc) {
      //     query = { paginate: true,  page, alpha_sort: 'asc'}
      //   }else {
      //     query = { paginate: true ,page, alpha_sort: 'desc'}
      //   }
      // }
      const field = this.active_column

      if(this.is_sort_ascdesc_enable) {
        if(this.is_sort_asc) {
        query = { paginate: true, page, ascdesc_sort: 'asc', field}
      }else {
        query = { paginate: true, page, ascdesc_sort: 'desc', field}
      }
      }

      if(!this.is_alpha_enable && !this.is_sort_ascdesc_enable) {
        query = { paginate: true, page,  order_field: 'property_name', ranking: 'asc'.toUpperCase() }
      }
      return this.allProperty(query).then((response) => {
        // eslint-disable-next-line no-console
        this.page = page
        this.properties = response.data
        this.totalRows = response.data.total
      })
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    uploadFiles: function () {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload);
      this.fileRecordsForUpload = [];
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
      const files = this.fileRecordsForUpload


      files.forEach(async file => {
        const data = await toBase64(file)
        this.galleries.push({ file: data })
      })

   
    },
    onBeforeDelete: function (fileRecord) {
       /* eslint-disable */ 
    
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.galleries.splice(i, 1)
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm('Are you sure you want to delete?')) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
     
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
        this.galleries.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }

     
    },

    showModal() {
      this.$bvModal.show("app-modal");
    },
    hideModal() {
      this.getAllProperty()
      this.$bvModal.hide("app-modal");
      // this.$refs.vueFileAgent.deleteFileRecord(this.fileGalleryRecords)
      this.is_add_new = false;
      this.is_update = false;
      this.is_tab_active = 1;
      const tab_view = this.tab_items.filter(item => item.id !== 7);
      this.tab_items = tab_view;
      this.client = null
      this.options = [],
        this.emptyData()
    },
    showFormContent(id) {
      if (id === 2 && this.property.property_name === '' && this.property_name_option === true && this.street_address !== '') {
        this.property.property_name = `${this.property.street_address}`
      }
      this.is_tab_active = id;

    },
    getImage(image) {
      if (image !== null || image !== '') {
        return `background: url(${process.env.VUE_APP_STORAGE_PATH + image})`
      }
      return image
    },
    getSourceImage(image) {
      if (image !== null || image !== '') {
        return `${process.env.VUE_APP_STORAGE_PATH + image}`
      }
      return image
    },
    addNew() {
      this.is_add_new = true;
      this.is_update = false;
      this.showModal();
    },
    showDetails(item) {
      // eslint-disable-next-line no-console
      this.property = item
      this.property.available_property_date = moment(item.available_property_date).toDate();
      this.property.lease_start_date = moment(item.lease_start_date).toDate();
      this.property.lease_end_date = moment(item.lease_end_date).toDate();
      this.property_status = item.status
      this.is_add_new = false;
      this.is_update = true;
      const tab_view = this.tab_items.filter(item => item.id === 7);
      if (tab_view.length < 1) {
        this.tab_items.push({ id: 7, title: "Status" })
      }
      this.showClient(item.client_id).then((response) => {
        this.client = response.data
      })
      this.showModal()
    },
    addAmenityInput() {
      if (this.property.amenity.length < 7) {
        this.property.amenity.push({
          name: null,
        })
      } else {
        this.runErrorToast("Sorry you can not add more than 7 amenities fields")
      }
    },
    runErrorToast(message) {
      Toastify({
        text: message,
        gravity: "top", // `top` or `bottom`
        position: "center", // `left`, `center` or `right`
        duration: 2000,
        style: {
          background: "red",
        }
      }).showToast();
    },
    changeAmenity(event) {
      // eslint-disable-next-line no-console
      const data = this.property.amenity.filter((item => item.name === event.target.value));

      if (data.length > 1) {
        this.runErrorToast(`Sorry you have already selected this amenity type ${event.target.value}`)
        const item = data[1];
        item.name = ""
      }

    },
    changePropertyType(event) {
      // eslint-disable-next-line no-console
      const data = this.property.property_type.filter((item => item.name === event.target.value));

      if (data.length > 1) {
        this.runErrorToast(`Sorry you have already selected this property type ${event.target.value}`)
        const item = data[1];
        item.name = ""
      }

    },
    changeParkingType(event) {
      // eslint-disable-next-line no-console
      const data = this.property.parking_type.filter((item => item.name === event.target.value));

      if (data.length > 1) {
        this.runErrorToast(`Sorry you have already selected this parking type ${event.target.value}`)
        const item = data[1];
        item.name = ""
      }

    },
    changeUtilityIncluded(event) {
      // eslint-disable-next-line no-console
      const data = this.property.utility_include.filter((item => item.name === event.target.value));

      if (data.length > 1) {
        this.runErrorToast(`Sorry you have already selected this utility type ${event.target.value}`)
        const item = data[1];
        item.name = ""
      }

    },
    addPropertyInput() {
      if (this.property.property_type.length < 7) {
        this.property.property_type.push({
          name: null,
        })
      } else {
        this.runErrorToast("Sorry you can not add more than 7 property type fields")
      }
    },
    addParkingTypeInput() {
      if (this.property.parking_type.length < 5) {
        this.property.parking_type.push({
          name: null,
        })
      } else {
        this.runErrorToast("Sorry you can not add more than 5 parking type fields")
      }
    },
    addUtilityIncludeInput() {
      if (this.property.utility_include.length < 5) {
        this.property.utility_include.push({
          name: null,
        })
      } else {
        this.runErrorToast("Sorry you can not add more than 5 utility included fields")
      }
    },
    removeAmenityInput(index) {
      const item = this.property.amenity[index]
      if (item.id !== undefined) {
        this.deleteAmenity(item.id).then(() => {
          this.getAllProperty()
        })
      }
      this.property.amenity.splice(index, 1)
    },
    updateAmenityInput(index) {
      const item = this.property.amenity[index]
      if (item.id !== undefined) {
        ``
        this.updateAmenity(item).then(() => {
          this.getAllProperty()
        })
      } else {
        item.property_id = this.property.id
        this.createAmenity(item).then((response) => {
          this.getAllProperty()
          item.id = response.data.id
        })
      }
    },
    removeProperyTypeInput(index) {
      const item = this.property.property_type[index]
      if (item.id !== undefined) {
        this.deletePropertyType(item.id).then(() => {
          this.getAllProperty()
        })
      }
      this.property.property_type.splice(index, 1)
    },
    updatePropertyTypeInput(index) {
      const item = this.property.property_type[index]
      if (item.id !== undefined) {
        this.updatePropertyType(item).then(() => {
          this.getAllProperty()
        })
      } else {
        item.property_id = this.property.id
        this.createPropertyType(item).then((response) => {
          this.getAllProperty()
          item.id = response.data.id
        })
      }
    },
    removeParkingTypeInput(index) {
      const item = this.property.parking_type[index]
      if (item.id !== undefined) {
        this.deleteParkingType(item.id).then(() => {
          this.getAllProperty()
        })
      }
      this.property.parking_type.splice(index, 1)
    },
    updateParkingTypeInput(index) {
      const item = this.property.parking_type[index]
      if (item.id !== undefined) {
        this.updateParkingType(item).then(() => {
          this.getAllProperty()
        })
      } else {
        item.property_id = this.property.id
        this.createParkingType(item).then((response) => {
          this.getAllProperty()
          item.id = response.data.id
        })
      }
    },
    removeUtilityIncludedInput(index) {
      const item = this.property.utility_include[index]
      if (item.id !== undefined) {
        this.deleteUtility(item.id).then(() => {
          this.getAllProperty()
        })
      }
      this.property.utility_include.splice(index, 1)
    },
    updateUtilityIncludeInput(index) {
      const item = this.property.utility_include[index]
      if (item.id !== undefined) {
        this.updateUtility(item).then(() => {
          this.getAllProperty()
        })
      } else {
        item.property_id = this.property.id
        this.createUtility(item).then((response) => {
          this.getAllProperty()
          item.id = response.data.id
        })
      }
    },
    createNewProperty() {
      this.submitted = true;
      const importantData = this.property;
      if(this.client?.id === undefined){
        importantData.client_id = null
      }else{
        delete importantData.client_id
      }

      if(importantData.available_property_date === null) {
        importantData.available_property_date = null
      }

      if(importantData.parking_spaces == 'yes' || importantData.parking_spaces === 'available for rent'){
        if(importantData.parking_fees === null){
          importantData.parking_fees = null
        }

        if(importantData.parking_number === '') {
          importantData.parking_number = ''
        }
       
       
      }else{
        delete importantData.parking_fees
        delete importantData.parking_number
      }

      if(importantData.is_lease_the_start_date_and_end_date === 'yes') {
        if(importantData.lease_start_date === null){
          importantData.lease_start_date = null
        }
        if(importantData.lease_end_date === null){
          importantData.lease_end_date = null
        }
        
      }else{
        delete importantData.lease_start_date
        delete importantData.lease_end_date
      }
      const validateObject = this.validateObjectData(importantData)
      const amenitiesIncompleteValue = this.validateCustomInputItems(this.property.amenity, 'name', 'amenities')
      const parkingIncompleteValue = this.validateCustomInputItems(this.property.parking_type, 'name', 'parking type')
      const utilityIncompleteValue = this.validateCustomInputItems(this.property.utility_include, 'name', 'utilities included') 
      // if(validateObject.length > 0)  return;
      // stop here if form is invalid
      this.$v.$touch();
     
      if(validateObject.length > 0){return};

      if (this.$v.$invalid) {
        return;
      } else {
        if(amenitiesIncompleteValue.length > 0) return;
        if(parkingIncompleteValue.length > 0) return;
        if(utilityIncompleteValue.length > 0) return;
        this.property.property_gallery = this.galleries
        if (this.property.available_property_date) {
          this.property.available_property_date = moment(this.property.available_property_date).format('YYYY-MM-DD');
        }
        if (this.property.lease_start_date) {
          this.property.lease_start_date = moment(this.property.lease_start_date).format('YYYY-MM-DD');
        }else{
          delete this.property.lease_end_date
        }

        if (this.property.lease_end_date) {
          this.property.lease_end_date = moment(this.property.lease_end_date).format('YYYY-MM-DD');
        }else{
          delete this.property.lease_end_date
        }
        const data = this.property
        if(this.property.monthly_rent_wanted.length > 0) {
          data.monthly_rent_wanted = this.formatMoneyAmount(this.property.monthly_rent_wanted);
        }
        
        if(this.property.parking_fees != null) {
          data.parking_fees = this.formatMoneyAmount(this.property.parking_fees);
        }
        
        data.client_id = this.client.id

        this.createProperty(data).then(() => {
          this.hideModal()
          this.emptyData()
        })
      }
      this.submitted = false;
    },
    updatePropertyItem() {
      this.submitted = true;
      // stop here if form is invalid
      const importantData = this.property;
      delete importantData.available_yeardate
      if(this.client?.id === undefined){
        importantData.client_id = null
      }else{
        delete importantData.client_id
      }

      if(importantData.available_property_date === null) {
        importantData.available_property_date = null
      }

      if(importantData.parking_spaces == 'yes' || importantData.parking_spaces === 'available for rent'){
        if(importantData.parking_fees === null){
          importantData.parking_fees = null
        }

        if(importantData.parking_number === '') {
          importantData.parking_number = ''
        }
       
       
      }else{
        delete importantData.parking_fees
        delete importantData.parking_number
      }

      if(importantData.is_lease_the_start_date_and_end_date === 'yes') {
        if(importantData.lease_start_date === null){
          importantData.lease_start_date = null
        }
        if(importantData.lease_end_date === null){
          importantData.lease_end_date = null
        }
        
      }else{
        delete importantData.lease_start_date
        delete importantData.lease_end_date
      }
      const validateObject = this.validateObjectData(importantData)
      // if(validateObject.length > 0)  return;
      // stop here if form is invalid
      this.$v.$touch();
     
      if(validateObject.length > 0){return};
      if (this.$v.$invalid) {
        return;
      } else {
        delete this.property.amenity
        delete this.property.parking_type
        delete this.property.utility_include
        delete this.property.property_gallery
        const data = this.property
        data.client_id = this.client.id
        if(this.property.monthly_rent_wanted.length > 0) {
          data.monthly_rent_wanted = this.formatMoneyAmount(this.property.monthly_rent_wanted);
        }
        
        if(this.property.parking_fees != null) {
          data.parking_fees = this.formatMoneyAmount(this.property.parking_fees);
        }
        
        this.updateProperty(this.property).then(() => {
          this.hideModal()
          this.emptyData()
        })
      }
      this.submitted = false;
    },
    deletePropertyItem() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.deleteProperty(this.property.id).then(() => {
            this.hideModal()
            this.emptyData()
          })
        }
      });
    },
    emptyData() {
      this.property = {
        property_name: "",
        description: "",
        monthly_rent_wanted: "",
        min_lease_term: null,
        max_lease_term: null,
        min_security_deposit: null,
        bedroom: "",
        bath_full: "",
        bath_half: "",
        size: "",
        street_address: "",
        city: "",
        state: "",
        zip_code: "",
        longitude: "",
        latitude: "",
        furnished: "yes",
        featured: 'no',
        parking_spaces: "no",
        client_id: null,
        is_lease_the_start_date_and_end_date: "no",
        parking_number: "",
        parking_fees: null,
        amenity: [],
        parking_type: [],
        utility_include: [],
        property_type: "",
        property_gallery: [],
        available_property_date: null,
        lease_start_date: null,
        lease_end_date: null,
      }
      this.galleries = []
      this.fileRecords = [];
      this.galleries = [];
      this.fileRecordsForUpload = [];
    },
    removeGalleryFile(index) {
      const item = this.property.property_gallery[index]
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          if (item.id !== undefined) {
            this.deletePropertyGallery(item.id).then(() => {
              this.getAllProperty()
            })
          }
          this.property.property_gallery.splice(index, 1)
        }
      })
    },
    addNewGallery() {
      if(this.galleries.length < 1) {
        return  this.runErrorToast("No images uploaded yet!")
      }
      const data = {
        property_id: this.property.id,
        property_gallery: this.galleries,
      }
      this.createPropertyGallery(data).then((response) => {
        this.getAllProperty()
        this.property.property_gallery = response.data
        this.$refs.vueFileAgent.deleteFileRecord(this.fileGalleryRecords)
        this.fileRecords = [];
        this.galleries = [];
        this.fileRecordsForUpload = [];
      })
    },
    toggleSwitch(status) {
      if (this.property_status === status) return;
      this.property.status = status
      const data = this.property
      data.status = status;
      this.updateProperty(data).then(() => {
        this.getAllProperty()
        this.property_status = status
      })

    },
    updatePropertyName() {

    },
    toggleStatusChange() {
      const data = this.property
      this.updateProperty(data).then(() => {
        this.getAllProperty()
      })
    },

    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    // eslint-disable-next-line no-unused-vars
    search: _.debounce((loading, search, vm) => {
      vm.allClient({ paginate: true, keyword: search }).then((response) => {
        // eslint-disable-next-line no-console
        vm.options = response.data.data
        loading(false)
      })
    }, 100),

    handleSearchOption(event) {
      // eslint-disable-next-line no-console
      this.active_search_option = event.target.value;
      this.client = null;
      this.lease_start_date = null;
      this.lease_end_date = null;
      this.availability_date = null;
      this.status = null;
      this.property_type = null;
      this.getAllProperty();
    },
    handlePropertyTypeOption() {
      return this.allPropertyByPropertyType({property_type: this.property_type, page: this.page}).then(response => {
        this.properties = response.data
        this.totalRows = response.data.total
      })
    },
    handlePropertyStatusOption() {
      const active_option = this.active_search_option;
      if(active_option == 2){
        return this.searchReportForPropertyOwner()
      }
      return this.allPropertyByStatus({status: this.status, page: this.page}).then(response => {
        this.properties = response.data
        this.totalRows = response.data.total
      })

    },
    filterPropertyWithAvailabilityDate() {
      return this.allPropertyByAvailabilityDate({availability_date: this.availability_date, page: this.page}).then(response => {
        this.properties = response.data
        this.totalRows = response.data.total
      })
    },
    filterPropertWithLeaseStartDateEndDate() {
      // If start_date is null, return early
      if (this.lease_start_date === null) {
        return;
      }

      // If end_date is null, return early
      if (this.lease_end_date === null) {
        return;
      }

      return this.allPropertyByLeaseStartDateAndLeaseEndDate({lease_start_date: this.lease_start_date, lease_end_date: this.lease_end_date, page: this.page}).then(response => {
        this.properties = response.data
        this.totalRows = response.data.total
      })
    },
    searchReportForPropertyOwner() {
      const active_option = this.active_search_option;
      let data;
      if(active_option == 1) {
        data = { client_id: this.client.id , page: this.page}
      }

      if(active_option == 2 ){
        data = { client_id: this.client.id, status: this.status, page: this.page }
      }

      return this.allPropertyByPropertyOwnerWithStatus(data).then(response => {
        this.properties = response.data
        this.totalRows = response.data.total
      })
    },
 /* eslint-disable */
    handleImageAdded(event) {
      const newGallery = this.property.property_gallery.map((item, index) => ({...item, image_position: index}))

      this.orderPropertyGallery({gallery: newGallery}).then(() => {
        this.property.property_gallery = newGallery
        this.getAllProperty()
      })
      
    },
    handleImageRemoved(event) {
          // eslint-disable-next-line no-console
      console.log('...removed', event)
    },
    handleImageMoved(event) {
      /* eslint-disable */
      console.log('...moved', event)
      console.log('...new gallery', property.property_gallery)
    },
    async handleSortAscDec() {
      this.is_alpha_enable = false;
      this.is_sort_ascdesc_enable = true
      this.is_sort_asc = !this.is_sort_asc

      if(this.is_sort_ascdesc_enable) {
        let query;
      if(this.is_sort_asc) {
        query = { paginate: true,  ascdesc_sort: 'asc', page: this.page}
      }else {
        query = { paginate: true, ascdesc_sort: 'desc', page: this.page}
      }
      await this.allProperty(query).then((response) => {
        
        this.properties = response.data
        this.totalRows = response.data.total
        // eslint-disable-next-line no-console
      })
      }
      
    },
    async handleSortAlphaAscDec() {
      this.is_sort_ascdesc_enable = false
      this.is_alpha_enable = true;
      this.is_sort_alha_asc = !this.is_sort_alha_asc
   
      if(this.is_alpha_enable) {
        let query;
      if(this.is_sort_alha_asc) {
        query = { paginate: true,  alpha_sort: 'asc', page: this.page}
      }else {
        query = { paginate: true , alpha_sort: 'desc', page: this.page}
      }
      await this.allProperty(query).then((response) => {
        
        this.properties = response.data
        this.totalRows = response.data.total
        // eslint-disable-next-line no-console
      })
      }
     
    },

    async handleTableHeadClicked(item) {
      this.active_column = item;
      this.is_alpha_enable = false;
      this.is_sort_ascdesc_enable = true
      this.is_sort_asc = !this.is_sort_asc
      if(this.is_sort_ascdesc_enable) {
        let query;
      if(this.is_sort_asc) {
        query = { paginate: true,  ascdesc_sort: 'asc', page: this.page, field: item}
      }else {
        query = { paginate: true, ascdesc_sort: 'desc', page: this.page, field: item}
      }
      await this.allProperty(query).then((response) => {
        
        this.properties = response.data
        this.totalRows = response.data.total
        // eslint-disable-next-line no-console
      })
    }
     
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <b-card no-body class="mt-5">
          <b-card-body>
            <b-card-title class="d-flex mb-5 align-items-center justify-content-between">
              <h1 class="card-title">Property List</h1>
              <a href="javascript:void(0);" class="btn btn-outline-success btn-block" @click="addNew">Add New</a>
            </b-card-title>

            <!-- <b-card-title>
            <div class="search_input_option_view">
              <div class="search_option_input_view">
                <label class="form-label mt-2" for="item1">Search Option for Property</label>
                <select v-model="active_search_option" class="form-control" @change="handleSearchOption">
                  <option v-for="{ id, type }  of search_options" :value="id" :key="id"> {{ type }} </option>
                </select>
              </div>
            </div>
          </b-card-title> -->
          <!-- <div class="search_input_option_view">
            <div class="property_search_view" style="width:300px;" v-if="active_search_option == 7">
                <label class="form-label" for="validationCustom20">Property Name</label>
                <input id="validationCustom20" v-model="search_data" type="text" class="form-control"
                  placeholder="type property name here" />
              </div>

              <div class="property_search_view" v-if="active_search_option == 1 || active_search_option == 2">
                <label class="form-label mt-2" for="item1">Property Owner(Client)</label>
                <v-select v-model="property_owner" label="full_name" :filterable="false" :options="options"
                  @search="onSearch" class="" :placeholder="'Select property owner'"
                  @input="searchReportForPropertyOwner">
                  <template slot="no-options">
                    type to search property owner
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.full_name }}
                    </div>
                  </template>
                </v-select>
              </div>

              <div class="property_search_view" v-if="active_search_option == 3">
                <div class="search_option_input_view">
                <label class="form-label mt-2" for="item1">Search Property for proeprty type</label>
                <select v-model="property_type" class="form-control" @change="handlePropertyTypeOption">
                  <option class="text-capitalize" v-for="({ name }, index ) of property_type_options" :value="name" :key="index"> {{ name }} </option>
                </select>
              </div>
              </div>

              <div class="property_search_view" v-if="active_search_option == 4 || active_search_option == 2">
                <div class="search_option_input_view">
                <label class="form-label mt-2" for="item1">Status</label>
                <select v-model="status" class="form-control" @change="handlePropertyStatusOption">
                  <option class="text-capitalize" v-for="({ name }, index ) of status_options" :value="name" :key="index"> {{ name }} </option>
                </select>
              </div>
              </div>

              <div class="property_search_view" v-if="active_search_option == 5">
                <label class="form-label mt-2" for="item1">Availability Date</label><br />
                  <date-picker
                    id="item1"
                    v-model="availability_date"
                    format="DD MMMM, YYYY"
                    class=""
                    lang="en"
                    @change="filterPropertyWithAvailabilityDate"
                    style="width: 100px;"
                  ></date-picker>
              </div>

              <div class="date_range_view" v-if="active_search_option == 6">
              <div class="split">
                <label class="form-label mt-2" for="item1">Lease Start Date</label>
                  <date-picker
                    id="item1"
                    v-model="lease_start_date"
                    format="DD MMMM, YYYY"
                    class=""
                    lang="en"
                    @change="filterPropertWithLeaseStartDateEndDate"
                  ></date-picker>
              </div>
              <div class="split">
                <label class="form-label mt-2" for="item1">Lease End Date</label>
                  <date-picker
                    id="item1"
                    v-model="lease_end_date"
                    format="DD MMMM, YYYY"
                    class=""
                    lang="en"
                    @change="filterPropertWithLeaseStartDateEndDate"
                  ></date-picker>
              </div>
            </div>

          </div> -->
   
          <!-- <b-card-text>
       
            <div class="sort_group_box">
              <div class="sort_box" @click="handleSortAlphaAscDec">
               <div class="app_tootip is_toolip_left">Sort Property In Alphabetical Order</div>
                <i class=" fas fa-sort-alpha-down-alt" id="sort-2-tooltip" v-if="is_sort_alha_asc === true"></i>
                <i class="fas fa-sort-alpha-up" id="sort-2-tooltip" v-else></i>
              </div>
              <div class="sort_box" @click="handleSortAscDec">
                <div class="app_tootip">Sort Property In {{ is_sort_asc === true ? 'Descending Order' : 'Ascending Order' }}</div>
                <i class="fas fa-sort-amount-up" id="sort-2-tooltip" v-if="is_sort_asc === true"></i>
                <i class=" fas fa-sort-amount-down-alt" id="sort-2-tooltip" v-else></i>
              </div>
          </div>

        </b-card-text> -->
            <b-card-text>
              <!-- Table -->
              <div class="table-responsive mb-0 datatables property_table">
                <b-table :items="properties.data"
                id="app-table"
                striped="striped"
                borderless="borderless"
                 outlined="outlined"
                 fixed="fixed"
                 sticky-header="true"
                :fields="fields" responsive   @row-clicked="showDetails"
                :busy="false"
                primary-key="property_name"
                :tbody-transition-props="transProps"
                @head-clicked="handleTableHeadClicked"
                >

                <!-- <template #table-busy>
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle"></b-spinner><br/>
          <strong>Loading...</strong>
        </div>
      </template> -->

      <template #head()="data">
        <div class="table_heading_view">
          <span class="">{{ data.label }}</span>
        <i class="fas fa-sort-amount-down-alt  text-success" id="sort-2-tooltip" v-if="is_sort_asc && data.column === active_column"></i>
        <i class="fas fa-sort-amount-up  text-info" id="sort-2-tooltip" v-if="!is_sort_asc && data.column === active_column"></i>
        </div>
        
      </template>
                  
                  <template #cell(status)="data">
                    <span class="text-capitalize text-bold" :class="data.value === 'available' ? 'text-success' :
                      data.value === 'rented' ? 'text-info' : data.value === 'maintainance' ? 'text-warning' : 'text-danger'

                      "><strong>{{ data.value }}</strong></span>

                  </template>
                  <template #cell(bedroom)="data">
                    {{ data.value == 0 ? 'Studio': data.value }}
                  </template>
                  <template #cell(monthly_rent_wanted)="data">
                    {{ formatCurrency(data.value) }}
                  </template>

                  <template #cell(available_property_date)="data">
                    {{ formDate(data.value) }}
                  </template>

                  <template #cell(lease_start_date)="data">
                    {{ data.item.is_lease_the_start_date_and_end_date === "no" ? '-' : 
                    formDate(data.value) }}
                  </template>

                  <template #cell(lease_end_date)="data">
                    {{ data.item.is_lease_the_start_date_and_end_date === "no" ? '-' : 
                    formDate(data.value) }}
                  </template>

                  <template #cell(size)="data">{{ data.value }} sf</template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-end">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                        @change="onPageChange"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card-body>
        </b-card>

        <!-- form -->

        <b-modal id="app-modal" :title="is_update ? `Update Property - ${property.property_name}` : 'Add Property'"
          title-class="font-18" size="property" hide-footer @hide="hideModal">
          <div class="bp_form_box">
            <div class="bp_form_side_bar">
              <div v-for="(data, index) in tab_items" :key="index" class="bp_tab_link"
                :class="is_tab_active === data.id ? 'bp_active' : ''" @click="showFormContent(data.id)">
                <div class="bp_tab_counter">
                  {{ index + 1 }}
                </div>
                <div class="bp_text_view">
                  <span> {{ data.title }} </span>
                </div>
              </div>
            </div>

            <div class="bp_form_view">
              <div class="form_content">
                <form class="needs-validation">
                  <div class="row" v-if="is_tab_active === 1">
                    <div class="col-md-8 mb-3">
                      <label class="form-label" for="property_type">Clients</label>
                      <v-select v-model="client" label="full_name" :filterable="false" :options="options"
                        @search="onSearch" class="" :placeholder="'type to search client'">
                        <!-- <template slot="no-options">
                          type to search property owner
                        </template> -->
                        <template slot="option" slot-scope="option">
                          <div class="d-center">

                            {{ option.full_name }}
                          </div>
                        </template>
                      </v-select>
                    </div>
                    <div class="col-md-12"></div>
                    <div class="col-md-4">
                      <div class="mb-4">
                        <label class="form-label" for="property_type">Property Type</label>
                        <select class="form-select form-control" id="validationCustom05" v-model="property.property_type">
                          <!-- <option selected value="" disabled></option> -->
                          <option v-for="(item, index) in property_types" :key="index">{{ item.value }}</option>
                        </select>
                        <div v-if="submitted && $v.property.property_type.$error" class="invalid-feedback">
                          <span v-if="!$v.property.property_type.required">This value is required.</span>
                        </div>
                      </div>
                    </div>

                    <!-- bedroom, bathroom, size, year built -->
                    <div class="col-md-2">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom05">Bed</label>

                        <select class="form-select form-control" id="validationCustom05" v-model="property.bedroom"
                          :class="{
                            'is-invalid': submitted && $v.property.bedroom.$error,
                          }">
                          <!-- <option selected value="" disabled hidden>Bed</option> -->
                          <option v-for="(item, index) in bedroom_options" :key="index" :value="item.value">{{ item.name
                          }}</option>
                        </select>
                        <div v-if="submitted && $v.property.bedroom.$error" class="invalid-feedback">
                          <span v-if="!$v.property.bedroom.required">This value is required.</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom05">Bath/F</label>
                        <select class="form-select form-control" id="validationCustom05" v-model="property.bath_full"
                          :class="{
                            'is-invalid': submitted && $v.property.bath_full.$error,
                          }">
                          <!-- <option selected value="" disabled hidden>Bath/F</option> -->
                          <option v-for="(item, index) in bath_full_option" :key="index" :value="item.value">{{ item.name
                          }}</option>
                        </select>

                        <div v-if="submitted && $v.property.bath_full.$error" class="invalid-feedback">
                          <span v-if="!$v.property.bath_full.required">This value is required.</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom05">Bath/H</label>
                        <select class="form-select form-control" id="validationCustom05" v-model="property.bath_half"
                          :class="{
                            'is-invalid': submitted && $v.property.bath_half.$error,
                          }">
                          <!-- <option selected value="" disabled hidden>Bath/H</option> -->
                          <option v-for="(item, index) in bath_half_option" :key="index" :value="item.value">{{ item.name
                          }}</option>
                        </select>
                        <div v-if="submitted && $v.property.bath_half.$error" class="invalid-feedback">
                          <span v-if="!$v.property.bath_half.required">This value is required.</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom05">Size (sq ft)</label>
                        <input id="validationCustom05" v-model="property.size" type="text" class="form-control"
                          placeholder="" :class="{
                            'is-invalid': submitted && $v.property.size.$error,
                          }" />
                        <div v-if="submitted && $v.property.size.$error" class="invalid-feedback">
                          <span v-if="!$v.property.size.required">This value is required.</span>
                        </div>
                      </div>
                    </div>


                    <div class="col-md-9">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom05">Street Address</label>
                        <input id="validationCustom05" v-model="property.street_address" type="text" class="form-control"
                          placeholder="" :class="{
                            'is-invalid': submitted && $v.property.street_address.$error,
                          }" @input="updatePropertyName" />
                        <div v-if="submitted && $v.property.street_address.$error" class="invalid-feedback">
                          <span v-if="!$v.property.street_address.required">This value is required.</span>
                        </div>
                      </div>
                    </div>


                    <div class="col-md-12"></div>




                    <div class="col-md-4">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom05">City</label>
                        <input id="validationCustom05" v-model="property.city" type="text" class="form-control"
                          placeholder="" :class="{
                            'is-invalid': submitted && $v.property.city.$error,
                          }" />
                        <div v-if="submitted && $v.property.city.$error" class="invalid-feedback">
                          <span v-if="!$v.property.city.required">This value is required.</span>
                        </div>
                      </div>
                    </div>


                    <div class="col-md-2">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom05">State</label>
                        <input id="validationCustom05" v-model="property.state" type="text" class="form-control"
                          placeholder="" :class="{
                            'is-invalid': submitted && $v.property.state.$error,
                          }" />
                        <div v-if="submitted && $v.property.state.$error" class="invalid-feedback">
                          <span v-if="!$v.property.state.required">This value is required.</span>
                        </div>
                      </div>
                    </div>



                    <div class="col-md-3">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom05">Zip Code</label>
                        <input id="validationCustom05" v-model="property.zip_code" type="text" class="form-control"
                          placeholder="" :class="{
                            'is-invalid': submitted && $v.property.zip_code.$error,
                          }" />
                        <div v-if="submitted && $v.property.zip_code.$error" class="invalid-feedback">
                          <span v-if="!$v.property.zip_code.required">This value is required.</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12"></div>
                    <!-- <div class="col-md-4">
                      <div class="mb-4">

                        <input id="validationCustom05" v-model="property.subdivision" type="text" class="form-control"
                          placeholder="Subdivision" :class="{
                            'is-invalid': submitted && $v.property.subdivision.$error,
                          }" />
                        <div v-if="submitted && $v.property.subdivision.$error" class="invalid-feedback">
                          <span v-if="!$v.property.subdivision.required">This value is required.</span>
                        </div>
                      </div>
                    </div> -->

                    <div class="col-md-4">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom05">Latitude</label>
                        <input id="validationCustom05" v-model="property.latitude" type="text" class="form-control"
                          placeholder="" :class="{
                            'is-invalid': submitted && $v.property.latitude.$error,
                          }" />
                        <div v-if="submitted && $v.property.latitude.$error" class="invalid-feedback">
                          <span v-if="!$v.property.latitude.required">This value is required.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom05">Longitude</label>
                        <input id="validationCustom05" v-model="property.longitude" type="text" class="form-control"
                          placeholder="" :class="{
                            'is-invalid': submitted && $v.property.longitude.$error,
                          }" />
                        <div v-if="submitted && $v.property.longitude.$error" class="invalid-feedback">
                          <span v-if="!$v.property.longitude.required">This value is required.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12"></div>
         

               
                    <div class="8"></div>


                    <!-- <div class="col-md-3">
                  <div class="mb-4">
                    <label class="form-label" for="validationCustom05">Year Built</label>
                    <input id="validationCustom05" v-model="property.year_built" type="text" class="form-control"
                      placeholder="" :class="{
                        'is-invalid': submitted && $v.property.year_built.$error,
                      }" />
                    <div v-if="submitted && $v.property.year_built.$error" class="invalid-feedback">
                      <span v-if="!$v.property.year_built.required">This value is required.</span>
                    </div>
                  </div>
                </div> -->

                    <div class="col-md-12">
                      <hr />
                      <div class="d-flex align-items-center justify-content-between mb-5">
                        <h4>Add Amenities</h4>
                        <button type="button" class="btn btn-outline-success icon-btn" @click="addAmenityInput"><i
                            class="ion ion-md-add" style="font-size: 20px"></i></button>
                      </div>

                      <div class="form-item-display mb-2" v-for="(form, index) in property.amenity" :key="index">
                        <select class="form-select form-control" id="validationCustom05" v-model="form.name"
                          @change="changeAmenity">
                          <option v-for="(item, index) in amenities" :key="index">{{ item.value }}</option>

                        </select>
                        <button type="button" class="btn btn-outline-danger icon-btn"
                          @click="removeAmenityInput(index)"><i class="ion ion-ios-trash"
                            style="font-size: 17px"></i></button>
                        <button type="button" v-if="is_update" class="btn btn-outline-success icon-btn"
                          @click="updateAmenityInput(index)"><i class="ion ion-md-checkmark"
                            style="font-size: 17px"></i></button>
                      </div>
                    </div>




                  </div>

                  <div class="row" v-if="is_tab_active === 2">
                    <div class="col-md-6">
                      <div class="mb-4">
                        <label class="form-label" for="propertyName">Property Name</label>
                        <input id="propertyName" v-model="property.property_name" type="text" class="form-control"
                          placeholder="" name="" :class="{
                            'is-invalid': submitted && $v.property.property_name.$error,
                          }" />
                        <div v-if="submitted && $v.property.property_name.$error" class="invalid-feedback">
                          <span v-if="!$v.property.property_name.required">This value is required.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <toggle-button v-model="property_name_option" style="margin-top: 5px;" :width="80" :height="30"
                        :color="{ checked: '#1fde82', unchecked: '#FF0000' }"
                        :labels="{ checked: 'Auto', unchecked: 'Manual' }" />
                    </div>
                   
                    <div class="col-md-12">
                      <h5 class="mb-3">Property Description</h5>
                      <ckeditor v-model="property.description" :config="editorConfig" :editor="editor"></ckeditor>
                    </div>
                  </div>

                  <div class="row" v-if="is_tab_active === 3">
                    <div class="col-md-4">
                      <div class="mb-4">
                        <label class="form-label" for="monthly_rent_wanted">Monthly Rent Wanted</label>
                        <input id="monthly_rent_wanted" v-model.lazy="formattedMonthlyAmountValue" @input="formatInputValue" type="text"
                          class="form-control amount" placeholder="" value="Otto" :class="{
                            'is-invalid': submitted && $v.property.monthly_rent_wanted.$error,
                          }" />
                        <div v-if="submitted && $v.property.monthly_rent_wanted.$error" class="invalid-feedback">
                          <span v-if="!$v.property.monthly_rent_wanted.required">This value is required.</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom03">Min Lease Term</label>
                        <input id="validationCustom03" v-model.lazy="property.min_lease_term" type="text"
                          class="form-control" placeholder="" :class="{
                            'is-invalid': submitted && $v.property.min_lease_term.$error,
                          }" />
                        <div v-if="submitted && $v.property.min_lease_term.$error" class="invalid-feedback">
                          <span v-if="!$v.property.min_lease_term.required">This value is required.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom04">Max Lease Term</label>
                        <input id="validationCustom04" v-model="property.max_lease_term" type="text" class="form-control"
                          placeholder="" :class="{
                            'is-invalid': submitted && $v.property.max_lease_term.$error,
                          }" />
                        <div v-if="submitted && $v.property.max_lease_term.$error" class="invalid-feedback">
                          <span v-if="!$v.property.max_lease_term.required">This value is required.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom05">Security Deposit</label>
                        <input id="validationCustom05" v-model.lazy="formattedSecurityDeposite" type="text"
                          class="form-control" placeholder="" :class="{
                            'is-invalid': submitted && $v.property.min_security_deposit.$error,
                          }"/>
                        <div v-if="submitted && $v.property.min_security_deposit.$error" class="invalid-feedback">
                          <span v-if="!$v.property.min_security_deposit.required">This value is required.</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom05">Furnished</label>
                        <select class="form-select form-control" id="validationCustom05" v-model="property.furnished"
                          placeholder="" :class="{
                            'is-invalid': submitted && $v.property.furnished.$error,
                          }">
                          <option selected value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                        <div v-if="submitted && $v.property.furnished.$error" class="invalid-feedback">
                          <span v-if="!$v.property.furnished.required">This value is required.</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom05">Is property featured</label>
                        <select class="form-select form-control" id="validationCustom05" v-model="property.featured"
                          placeholder="" :class="{
                            'is-invalid': submitted && $v.property.featured.$error,
                          }">
                          <option selected value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                        <div v-if="submitted && $v.property.featured.$error" class="invalid-feedback">
                          <span v-if="!$v.property.featured.required">This value is required.</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <hr />
                      <div class="d-flex align-items-center justify-content-between mb-5">
                        <h4>Add Utility Included</h4>
                        <button type="button" class="btn btn-outline-success icon-btn" @click="addUtilityIncludeInput"><i
                            class="ion ion-md-add" style="font-size: 20px"></i></button>
                      </div>

                      <div class="form-item-display mb-2" v-for="(form, index) in property.utility_include" :key="index">
                        <select class="form-select form-control" id="validationCustom05" v-model="form.name"
                          @change="changeUtilityIncluded">
                          <option v-for="(item, index) in utility_includes" :key="index">{{ item.value }}</option>

                        </select>
                        <button type="button" class="btn btn-outline-danger icon-btn"
                          @click="removeUtilityIncludedInput(index)"><i class="ion ion-ios-trash"
                            style="font-size: 17px"></i></button>
                        <button type="button" v-if="is_update" class="btn btn-outline-success icon-btn"
                          @click="updateUtilityIncludeInput(index)"><i class="ion ion-md-checkmark"
                            style="font-size: 17px"></i></button>
                      </div>
                    </div>
                  </div>

                  <!-- amenity view -->
                  <!-- <div class="row" v-if="is_tab_active === 5">
   
              </div> -->

                  <!-- parking type view -->
                  <div class="row" v-if="is_tab_active === 4">
                    <div class="col-md-6">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom05">Parking</label>
                        <select class="form-select form-control" id="validationCustom05" v-model="property.parking_spaces"
                          placeholder="" :class="{
                            'is-invalid': submitted && $v.property.parking_spaces.$error,
                          }">
                          <option selected value="yes">Yes</option>
                          <option value="no">No</option>
                          <option value="available for rent">Available for rent</option>
                        </select>
                        <div v-if="submitted && $v.property.parking_spaces.$error" class="invalid-feedback">
                          <span v-if="!$v.property.parking_spaces.required">This value is required.</span>
                        </div>
                      </div>
                    </div>


                    <div class="col-md-3" v-if="property.parking_spaces != 'no'">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom05">Parking Fees</label>
                        <input id="validationCustom05" v-model.lazy="formattedParkingFees" type="text" class="form-control"
                           />
                      </div>
                    </div>
                    <div class="col-md-3" v-if="property.parking_spaces != 'no'">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom05">Parking Number</label>
                        <input id="validationCustom05" v-model="property.parking_number" type="text" class="form-control"
                          placeholder="" />
                      </div>
                    </div>
                    <div class="col-md-12" v-if="property.parking_spaces != 'no'">
                      <div class="d-flex align-items-center justify-content-between mb-5">
                        <h4>Add Parking Type</h4>
                        <button type="button" class="btn btn-outline-success icon-btn" @click="addParkingTypeInput"><i
                            class="ion ion-md-add" style="font-size: 20px"></i></button>
                      </div>

                      <div class="form-item-display mb-2" v-for="(form, index) in property.parking_type" :key="index">
                        <select class="form-select form-control" id="validationCustom05" v-model="form.name"
                          @change="changeParkingType">
                          <option v-for="(item, index) in parking_types" :key="index">{{ item.value }}</option>

                        </select>
                        <button type="button" class="btn btn-outline-danger icon-btn"
                          @click="removeParkingTypeInput(index)"><i class="ion ion-ios-trash"
                            style="font-size: 17px"></i></button>
                        <button type="button" v-if="is_update" class="btn btn-outline-success icon-btn"
                          @click="updateParkingTypeInput(index)"><i class="ion ion-md-checkmark"
                            style="font-size: 17px"></i></button>
                      </div>
                    </div>
                  </div>

                  <!-- property type -->
                  <!-- <div class="row" v-if="is_tab_active === 6">
                <div class="col-md-12">
                  <div class="d-flex align-items-center justify-content-between mb-5">
                    <h4>Add Property Type</h4>
                    <button type="button" class="btn btn-outline-success icon-btn" @click="addPropertyInput"><i
                        class="ion ion-md-add" style="font-size: 20px"></i></button>
                  </div>

                  <div class="form-item-display mb-2" v-for="(form, index) in property.property_type" :key="index">
                    <select class="form-select form-control" id="validationCustom05" v-model="form.name"
                      @change="changePropertyType">
                      <option v-for="(item, index) in property_type_options" :key="index">{{ item.name }}</option>

                    </select>
                    <button type="button" class="btn btn-outline-danger icon-btn"
                      @click="removeProperyTypeInput(index)"><i class="ion ion-ios-trash"
                        style="font-size: 17px"></i></button>
                    <button type="button" v-if="is_update" class="btn btn-outline-success icon-btn"
                      @click="updatePropertyTypeInput(index)"><i class="ion ion-md-checkmark"
                        style="font-size: 17px"></i></button>
                  </div>
                </div>
              </div> -->



                  <!-- Gallery View -->

                  <div class="row mb-3" v-if="is_tab_active === 5">
                    <div class="col-md-12">
                      <div class="d-flex align-items-center justify-content-between mb-4">
                        <h4>Add Property Images</h4>
                      </div>
                      <draggable v-model="property.property_gallery"  v-bind="dragOptions" @change ="handleImageAdded" @removed="handleImageRemoved" @moved="handleImageMoved" v-if="property.property_gallery.length > 0" >
                        <transition-group type="transition" name="flip-list" class="gallery_view_card mb-5" >
                        <div v-for="(item, index) in property.property_gallery" :key="item.id" class="col-md-3 mb-2 image__gallery__card">
                          <img alt="image" v-lazy="getSourceImage(item.file_name)" />
                          <div class="close_icon" @click="removeGalleryFile(index)">
                              <i class="ion ion-md-close"></i>
                            </div>
                          <!-- <div class="image__gallery__card" :style="getImage(item.file_name)">
                            
                          </div> -->
                        </div>
                      </transition-group>
                    </draggable>
                      <VueFileAgent ref="vueFileAgent" :theme="'list'" :multiple="true" :deletable="true" :meta="true"
                        :accept="'image/*'" :maxSize="'30MB'" :maxFiles="50" :helpText="'Choose images'" :errorText="{
                          type: 'Invalid file type. Only images Allowed',
                          size: 'Files should not exceed 30MB in size',
                        }" @select="filesSelected($event)" @beforedelete="onBeforeDelete($event)"
                        @delete="fileDeleted($event)" v-model="fileRecords"></VueFileAgent>
                    </div>
                  </div>

                  <!-- utility included type -->
                  <div class="row" v-if="is_tab_active === 6">
                    <div class="col-md-6">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom05">Date Available</label>
                        <date-picker v-model="property.available_property_date" format="DD MMMM, YYYY"
                          lang="en"></date-picker>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <hr />
                    </div>
                    <div class="mb-12">
                      <label class="form-label" for="validationCustom05">Is property currently leased?</label>
                      <select class="form-select form-control" id="validationCustom05"
                        v-model="property.is_lease_the_start_date_and_end_date" placeholder="" :class="{
                          'is-invalid': submitted && $v.property.is_lease_the_start_date_and_end_date.$error,
                        }">
                        <option selected value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                      <div v-if="submitted && $v.property.is_lease_the_start_date_and_end_date.$error"
                        class="invalid-feedback">
                        <span v-if="!$v.property.is_lease_the_start_date_and_end_date.required">This value is
                          required.</span>
                      </div>
                    </div>

                    <div class="col-md-6" v-if="property.is_lease_the_start_date_and_end_date != 'no'">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom05">Lease Start Date</label>
                        <date-picker v-model="property.lease_start_date" format="DD MMMM, YYYY" lang="en"></date-picker>
                      </div>
                    </div>

                    <div class="col-md-6" v-if="property.is_lease_the_start_date_and_end_date != 'no'">
                      <div class="mb-4">
                        <label class="form-label" for="validationCustom05">Lease End Date</label>
                        <date-picker v-model="property.lease_end_date" format="DD MMMM, YYYY" lang="en"></date-picker>

                      </div>
                    </div>
                  </div>

                  <div class="row" v-if="is_tab_active === 7">
                    <div class="col-md-12">
                      <div class="check_box_view mb-4">
                        <span style="font-size: 16px">Set Property Status</span>
                        <span class="badge badge-custom" :class="property.status === 'available' ? 'bg-success' :
                          property.status === 'rented' ? 'bg-info' : property.status === 'maintainance' ? 'bg-warning' : 'bg-danger'

                          "><strong>{{ property.status }}</strong></span>
                        <!-- <toggle-button :sync="true" :value="property_status === 'rented' ? true  : false" @change="toggleSwitch('rented')"  :width="60" :height="30" :color="{checked: '#1fde82', unchecked: '#7f8389'}" :labels="true"/> -->
                      </div>

                      <select class="form-select form-control mb-3" id="validationCustom05" v-model="property.status"
                        @change="toggleStatusChange">
                        <option value="available">Available</option>
                        <option value="rented">Rented</option>
                        <option value="maintainance">Maintainance</option>
                        <option selected value="cancelled">Cancelled</option>
                      </select>

                      <b-alert :variant="property.status === 'available' ? 'success' :
                          property.status === 'rented' ? 'info' : property.status === 'maintainance' ? 'warning' : 'danger'


                        " show>
                        {{ property.status === 'available' ? 'No tenant and Property is ready for a tenant' :
                          property.status === 'rented' ? 'Property is occupied by a tenant' :
                            property.status === 'maintainance' ? ' No tenant and the property is closed for maintainance' :
                              'Property is no longer available at this time' }}
                      </b-alert>
                    </div>

                    <!-- <div class="col-md-12">
                <hr />
                <div class="check_box_view">
                  <span style="font-size: 16px">Set Property as Vacant</span>
                  <toggle-button :sync="true" :value="property_status === 'available' ? true  : false"  @change="toggleSwitch('vacant')" :width="60" :height="30" :color="{checked: '#1fde82', unchecked: '#7f8389'}" :labels="true"/>
                </div>
              </div>

              <div class="col-md-12">
                <hr />
                <div class="check_box_view">
                  <span style="font-size: 16px">Set Property as Featured</span>
                  <toggle-button :sync="true" :value="property_status === 'featured' ? true  : false" @change="toggleSwitch('featured')" :width="60" :height="30" :color="{checked: '#1fde82', unchecked: '#7f8389'}" :labels="true"/>
                </div>
              </div>

              <div class="col-md-12 mt-5">
                <hr />
                <div class="check_box_view">
                  <span style="font-size: 16px">Set Property as Canceled</span>
                  <toggle-button :sync="true" :value="property_status === 'canceled' ? true  : false" @change="toggleSwitch('canceled')" :width="60" :height="30" :color="{checked: '#1fde82', unchecked: '#7f8389'}" :labels="true"/>
                </div>
              </div> -->

                  </div>

                </form>
              </div>

              <div v-if="is_update && is_tab_active !== 5 && is_update && is_tab_active !== 7 || is_add_new === true"
                class="button_view d-flex align-items-center justify-content-between">
                <button type="button" class="btn btn-outline-secondary" @click="hideModal">Cancel</button>
                <!-- <button type="button" v-if="is_update" class="btn btn-outline-danger"
              @click="deletePropertyItem">Delete</button> -->
                <button type="button" class="btn btn-outline-success"
                  @click="is_update ? updatePropertyItem() : createNewProperty()">Save</button>
              </div>

              <div class="button_view d-flex align-items-center justify-content-between"
                v-if="is_update && is_tab_active === 5">
                <button type="button" class="btn btn-outline-secondary" @click="hideModal">Cacel</button>
                <button type="button" class="btn btn-outline-success" @click="addNewGallery">Save</button>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
    <!-- <PageHeader :title="title" :items="items" /> -->

  </Layout>
</template>


<style scoped>
.v-select-wrapper {
  position: relative;
}

.placeholder {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #a9a9a9;
  pointer-events: none;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

table#app-table .flip-list-move {
  transition: transform 1s;
}
</style>
