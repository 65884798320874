import { mapState, mapGetters, mapActions } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const propertyTypeItemComputed = {
    ...mapState('propertytypeitem', {
        propertytypeitem:(state) => state.property_type_items,
    }),
    ...mapGetters('propertytypeitem', ['getPropertyTypeItem'])
}

// export property actions
export const propertyTypeItemMethods = mapActions('propertytypeitem', ['allPropertyTypeItem','createPropertyTypeItem', 'updatePropertyTypeItem', 'deletePropertyTypeItem',
])
